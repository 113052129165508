<template>
    <div>
        <v-form class="auth-form auth-form-doc" ref="form" v-model="valid">
            <div class="auth-card-form">
                <v-card 
                    width="100%"
                    height="100%"
                    elevation="0"
                    rounded="0">
                    <v-card-text>
                        <v-row class="auth-main-row-wrap">
                            <v-col v-if="false" cols="auto" sm="auto" md="auto" class="amrw-logo-part d-none d-lg-block">
                                <div class="d-flex flex-column" style="height:100%;">
                                    <v-row class="auth-logo-row mb-auto" no-gutters>
                                        <v-col cols="12" sm="12" md="12">
                                            <div class="d-flex align-center">
                                                <img class="al-logo" src="@/assets/logo_short.svg">
                                                <div class="auth-logo-title">
                                                    <div class="alt-main">Система Электронного</div>
                                                    <div class="alt-sub">Документооборота</div>
                                                </div>
                                                <div class="alt-integro">Интегро</div>
                                                <!--<img class="al-five" src="@/assets/six.svg">-->
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div>
                                        <v-row class="mt-0" no-gutters>
                                            <v-col cols="12">
                                                <v-btn 
                                                    class="ma-0"
                                                    color="light-blue lighten-4"
                                                    depressed
                                                    block
                                                    outlined
                                                    @click="agreementDlg"
                                                >
                                                {{ $t('Лицензионное_соглашение') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </v-col>
                            <v-col>
                                <div class="d-flex flex-column" style="height:100%;">
                                    <v-row class="flex-grow-0" no-gutters>
                                        <v-col cols="12" sm="12" md="12" class="text-right">
                                            <v-btn-toggle 
                                                :value="getLanguageId()"
                                                dense
                                                mandatory
                                                color="#fff"
                                                active-class="light-blue darken-2"
                                                borderless
                                                light
                                                background-color="transparent"
                                                class="auth-lang-selector"
                                            >

                                                <v-btn
                                                    :value="0"
                                                    @click="setLanguage('kk')"
                                                    small
                                                >
                                                    Қаз
                                                </v-btn>

                                                <v-btn
                                                    :value="1"
                                                    @click="setLanguage('ru')"
                                                    small
                                                >
                                                    Рус
                                                </v-btn>

                                            </v-btn-toggle>  
                                        </v-col>
                                    </v-row>
                                    <div class="auth-form-field-wrap d-flex align-center flex-grow-1">
                                        <div v-if="restorePassInstruction" style="width:100%;">
                                            <div class="bpi-pattern text-center" style="width:100%;">
                                                {{ $t('Инструкция_по_восстановлению_пароля', { phone: technicalSupport.phone, email: technicalSupport.email }) }}
                                            </div>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="12">
                                                    <a
                                                        class="text-decoration-none restore-pass"
                                                        @click="restorePassInstruction = !restorePassInstruction"
                                                    ><i class="fas fa-long-arrow-alt-left"></i> {{ $t('Назад') }}</a>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div v-else style="width:100%;">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="12">
                                                    <h2 class="login-form-title">{{ $t('Вход_в_систему') }}</h2>
                                                </v-col>
                                            </v-row>

                                            <v-tabs
                                                v-model="tab"
                                                grow
                                                background-color="transparent"
                                                class="login-form-selector-panel"
                                                color="#fff"
                                                slider-size="2"
                                                mobile-breakpoint="0"
                                            >
                                                <v-tabs-slider color="#fff"></v-tabs-slider>

                                                <v-tab>
                                                    {{ $t('По_логину') }}
                                                </v-tab>
                                                <v-tab>
                                                    {{ $t('По_ЭЦП') }}
                                                </v-tab>
                                            </v-tabs>

                                            <v-tabs-items
                                                v-model="tab"
                                                class="tab-content-panel"
                                            >
                                                <v-tab-item>
                                                    <v-row no-gutters>
                                                        <v-col cols="12" sm="12" md="12">
                                                            <v-text-field
                                                                :readonly="isPendingData"
                                                                :disabled="isPendingData"
                                                                :rules="[rules.required]"
                                                                v-model="login"
                                                                prepend-inner-icon="fas fa-user"
                                                                :placeholder="$t('Логин')"
                                                                required 
                                                                outlined 
                                                                background-color="#fff"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="12" sm="12" md="12">
                                                            <v-text-field 
                                                                :readonly="isPendingData"
                                                                :disabled="isPendingData"
                                                                :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                                                                :rules="[rules.required]"
                                                                :type="showPassword ? 'text' : 'password'"
                                                                v-model="password"
                                                                name="input-10-1"
                                                                @click:append="showPassword = !showPassword"
                                                                @keydown.enter="fetchAuthorize({ login, password })"
                                                                prepend-inner-icon="fas fa-lock" 
                                                                :placeholder="$t('Пароль')"
                                                                autocomplete="new-password"
                                                                required 
                                                                outlined 
                                                                background-color="#fff"
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mt-0" no-gutters>
                                                        <v-col cols="12">
                                                            <v-btn 
                                                                class="ma-0 send-auth-btn"
                                                                :loading="isPendingData"
                                                                :disabled="!valid || isPendingData"
                                                                color="light-blue darken-2"
                                                                depressed
                                                                @click="fetchAuthorize({ login, password })"
                                                                block
                                                                x-large
                                                            >
                                                                {{ $t('Войти') }}

                                                                <template v-slot:loader>
                                                                    <semipolar-spinner
                                                                        v-if="isPendingData"
                                                                        :animation-duration="2000"
                                                                        :size="50"
                                                                        color="white"
                                                                        class="auth-loader"
                                                                    />
                                                                </template>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="12" sm="12" md="12">
                                                            <a
                                                                class="text-decoration-none restore-pass"
                                                                @click="restorePassInstruction = !restorePassInstruction"
                                                            > {{ $t('Забыли_пароль?') }}</a>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                                <v-tab-item>
                                                    <EDSAuthPanel />
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </div>
                                    </div>
                                    <v-row class="flex-grow-0 bottom-panel-info" no-gutters>
                                        <v-col cols="6" sm="6" md="6">
                                            <div class="bpi-pattern">
                                                {{ $t('Техническая_поддержка') }}:
                                                <div class="d-flex">
                                                    <a
                                                        v-if="technicalSupport.phone"
                                                        :href="`tel:${technicalSupport.phone}`"
                                                        class="text-decoration-none">
                                                        <i class="fas fa-phone-alt"></i>
                                                        {{ technicalSupport.phone }}
                                                    </a>
                                                    <span class="mr-3" v-if="technicalSupport.phone && technicalSupport.email">,</span>
                                                    <a
                                                        v-if="technicalSupport.email"
                                                        :href="`mailto:${technicalSupport.email}`"
                                                        class="text-decoration-none">
                                                        <i class="fas fa-envelope"></i>
                                                        {{ technicalSupport.email }}
                                                    </a>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="6" class="d-flex justify-end">
                                            <div class="bpi-pattern d-flex align-center">
                                                <div class="d-flex">
                                                    {{ $t('Версия') }}: {{ getVersion() }}
                                                </div>
                                                <!-- <a href="#">{{ $t('Что_нового?') }}</a> -->
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </v-form>
        <img src="@/assets/13657907_5317174.jpg" id="bgFolder">
        <AgreementDlg ref="AgreementDlgRef" />
    </div>    
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SemipolarSpinner } from '../../node_modules/epic-spinners'
import i18n from '@/i18n';
import { httpAPI } from "@/api/httpAPI";
import EDSAuthPanel from "@/components/EDSAuthPanel.vue";
import AgreementDlg from '@/components/dialogs/AgreementDlg';

export default {
    components:{
        SemipolarSpinner,
        EDSAuthPanel,
        AgreementDlg
    },
    asyncComputed: {
        technicalSupport: {
            async get() {
                var response = await httpAPI({
                    url: `api/references/gettechnicalsupportinfo`,
                    method: 'GET',
                });
                
                if (response)
                    return response.data.payload;
                else
                    return { phone: "", email: "" };
            },
            default: () => ({ phone: "", email: "" })
        }
    },
    data: () => ({
        readOnly: false,
        disabled: false,
        loader: null,
        dialog: true,
        showPassword: false,
        login: '',
        password: '',
        currentLanguage: 1,
        valid: false,
        rules: {
            required: function (value) {
                return (value !== undefined && !!value) || i18n.t('Не_может_быть_пустым.');
            },
            min: function (value) {
                return (value !== undefined && value.length >= 3) || i18n.t('Не_менее_3_символов');
            },
        },
        tab: null,
        restorePassInstruction: false,
    }),
    methods: {
        ...mapActions('auth', ['fetchAuthorize']),
        setLanguage(lang) {
            localStorage.setItem('AppLocale', lang)
            i18n.locale = lang;
            this.$vuetify.lang.current = lang;
            this.$refs.form.validate();
        },
        getLanguageId() {
            switch (i18n.locale)
            {
                case "ru":
                    return 1;

                case "kk":
                    return 0;

                default:
                    return 1;
            }
        },
        isConnected(){
            return this.$store.getters.getBackendConnectionState === "Connected";
        },
        getVersion() {
            let isDebug = process?.env?.VUE_APP_DEBUG === "true";
            let version = process?.env?.VUE_APP_VERSION ?? "0.0.0.0.0000"
            return isDebug ? `${version}(DEBUG)` : version;
        },
        async agreementDlg(){
            try {
                await this.$store.dispatch('dialogs/userAgreement/open', {viewonly:true})
            }
            catch(ex) { return; }
        },
    },
    computed: {
        ...mapGetters('auth', ['getErrorMessage', 'isPendingData']),
        logoComponentName(){            
            if (process.env.VUE_APP_CONFIGURATION == 'C5')
                return 'LoginLogoC5';

            return 'LoginLogoC4';
        },
    },
    watch: {
        loader() {
            this.disabled = true;
        },
        getErrorMessage(newValue){
            if (newValue)
                this.$notify.alert(newValue, {durations: {alert: 0}});
        }
    }
};
</script>

<style lang="scss" scoped>
.auth-form *:not(.alt-main, .alt-sub, i, span){
    font-family: SegoeUI!important;
}
.login-form-title{
    margin-bottom: 26px;
    color: #fff; 
    text-align: center;
    font-weight: 500;
}
.restore-pass{
    margin-top: 26px;
    display: block;
    color: #fff;
    font-size: 16px;
}
.auth-form{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    z-index: 2;
}
.auth-form-mt-13{
    margin-top: 13px;
}
.auth-card-form{
    width: 100%;
    height: 100%;
}
.auth-main-row-wrap{
    height: 100%;
    margin: 0;
}
.auth-main-row-wrap>div{
    padding: 50px 50px 10px 50px;
}
.auth-main-row-wrap>div.amrw-logo-part{
    background: rgba(6, 83, 122, .8);
}
.auth-card-form .v-card{
    background-color: transparent;
}
.auth-logo-row::v-deep img{
    display: block;
}
.auth-logo-row::v-deep img.al-logo{
    height: 56px;
}
.auth-logo-row::v-deep img.al-five{
    height: 60px;
}
.auth-logo-row::v-deep .auth-logo-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 43px;
    position: relative;
}
.auth-logo-row::v-deep .auth-logo-title:after{
    content: '';
    position: absolute;
    width: 3px;
    top: 0;
    bottom: 0;
    background: #fff;
    left: 20px;
}
.auth-logo-row::v-deep .auth-logo-title>div{
    width: 100%;
    line-height: 1;
    text-transform: uppercase;
}
.auth-logo-row::v-deep .auth-logo-title .alt-main{
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    z-index: 2;
    font-family: BAHNSCHRIFT !important;
}
.auth-logo-row::v-deep .auth-logo-title .alt-sub{
    color: #fff;
    font-size: 18px;
    letter-spacing: 2px;
    z-index: 2;
    font-family: BAHNSCHRIFT !important;
}
.auth-logo-row::v-deep .auth-logo-title .alt-sub span{
    font-size: 22px;
    font-style: italic;
    color: #054594;
}
.auth-logo-row::v-deep .alt-integro{
    color: #fff;
    font-size: 42px;
    line-height: 1;
    z-index: 2;
    font-family: BAHNSCHRIFT !important;
    text-transform: uppercase;
    margin-top: 2px;
}
.auth-logo-row::v-deep .logo-title-column .auth-logo-title{
    margin-top: 15px;
}
.auth-logo-row::v-deep .logo-title-column .auth-logo-title .alt-main, .auth-logo-row::v-deep .logo-title-column .auth-logo-title .alt-sub{
    text-align: center;
}
.auth-logo-row::v-deep .logo-title-column .auth-logo-title .alt-main{
    color: #000;
    font-size: 20px;
}
.auth-logo-row::v-deep .logo-title-column .auth-logo-title .alt-sub{
    font-size: 12px;
}
.auth-form .v-card__actions{
    justify-content: end;
}
.auth-action-box{
    display: flex;
    justify-content: end;
}
.auth-lang-selector .v-btn--has-bg{
    background-color: rgba(255, 255, 255, .2); 
    color: #fff; 
}
.auth-form-field-wrap{
    width: 575px;
    margin: 0 auto;
}
.login-form-selector-panel{
    margin-bottom: 26px;
}
.login-form-selector-panel.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    color: #fff!important;
}
.tab-content-panel{
    overflow: visible!important;
}
.auth-loader{
    position: absolute!important;
    top: calc(50% - 25px);
    right: calc(50% - 25px);
}
.auth-form .v-card__text{
    padding: 0;
    height: 100%;
}
.auth-form-field-wrap::v-deep .send-auth-btn{
    color: #fff;
}
.auth-form-field-wrap::v-deep .v-btn.v-btn--disabled.v-btn--has-bg{
    background-color: rgba(255, 255, 255, .3) !important;
}
.auth-form-field-wrap::v-deep .select-outer-icon-inner-auth .v-input__append-outer{ 
    position: absolute!important;
    right: 34px;
    margin-top: 16px!important;
}
.auth-form-field-wrap::v-deep .select-outer-icon-inner-auth .v-select__selections{
    padding-right: 28px!important;
}
.auth-copyright{
    margin-top: 26px;
    color: #fff;
    font-size: 16px;
    line-height: 1;
}
.bottom-panel-info{
    margin: 0 -40px;
}
.bpi-pattern{
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    -moz-border-radius: 4px; /* Firefox */
    -webkit-border-radius: 4px; /* Safari, Google Chrome */
    -khtml-border-radius: 4px; /* KHTML */
    -o-border-radius: 4px; /* Opera */
    -ms-border-radius: 4px; /* IE8 */
    -icab-border-radius: 4px; /* Icab */
    border-radius: 4px; /* CSS3 */
    font-size: 16px;
    padding: 5px 10px;
    display: inline-block;
}
.bpi-pattern a{
    color: #fff;
}
#bgVideo{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
}
#bgFolder{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
}
#bgVideoBlurLayer{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;  
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

@media screen and (max-width: 1600px) {
    .auth-main-row-wrap>div{
        padding: 25px 40px 10px 40px;
    }
    .auth-form-field-wrap{
        width: 55%;
    }
    .auth-logo-row::v-deep img.al-five{
        height: 46px;
    }
    .bottom-panel-info{
        margin: 0 -30px;
    }
}
@media screen and (max-width: 780px) {
    .auth-form-field-wrap{
        width: 80%;
    } 
}

</style>